/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ParallaxProvider } from 'react-scroll-parallax';

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey="6LdXgcEdAAAAAIh6o5hoCAbui_AzmQYgm5rmQ4rB"
    language="ja"
  >
    <ParallaxProvider>
      <SimpleReactLightbox>{element}</SimpleReactLightbox>
    </ParallaxProvider>
  </GoogleReCaptchaProvider>
)
